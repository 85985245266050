import React from "react"
import Grid from "../../components/Grid/Grid"
import styled from "styled-components"
import Faq from "../../components/Faq"
import Seo from "../../components/Seo"
import PageIntro from "../../components/PageIntro/PageIntro"
import Layout from "../../components/Layout"

const ContentArea = styled.div`
  grid-column: 1 / 2;
  grid-row: 2;

  @media (min-width: 1200px) {
    grid-column: 2 / 4;
    grid-row: 1;
  }

  h1 {
    margin-top: 0;
    text-transform: capitalize;
  }

  p {
    margin-bottom: 40px;
  }
`

const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? "gray" : "white"};
  color: ${props => props.primary ? "white" : "gray"};

  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid gray;
  border-radius: 3px;
  a {
    text-decoration: none;
  }
`;

const XLink = ({ className, children }) => (
  <a className={className} href="/X-5-User-Manual-1_02.pdf">
    {children}
  </a>
);

const StyledLink = styled(XLink)`
  color: white;
  font-weight: bold;
`;

const ManualArea = styled.div`
  grid-column: 1 / 4;
  grid-row: 1;
`
const FaqBlock = styled.div`
  margin-top: 40px;
`

const IntroSection = styled.div`
  margin-top: 70px;
`

const Faqs = props => {
  return (<>
    <Layout {...props}>
      <Seo title="Support FAQs" />
      <IntroSection>
        <Grid>
          <PageIntro title="Support FAQs"
            subTitle="Frequently Asked Questions"
            paragraph="FAQ's, quick fixes, and official info on our X-Five Round and Square smartwatches." />
        </Grid>
      </IntroSection>
      <section>
        <Grid>
          <ManualArea>
            <h3>Manuals:</h3>
            <Button primary>
              <StyledLink>
                X-Five User Manual
              </StyledLink>
            </Button>
            {/* Commenting out until we release the new model
            <Link to="/X-5-1a-User-Manual.pdf">X-Five (version 1a) User Manual</Link>
          */}
          </ManualArea>
          <ContentArea>
            <FaqBlock>
              <Faq key="1"
                title="How do I charge my smartwatch?"
                description="Plug the charging cable into the USB port on your computer or a UL-certified USB wall charger. <b>We recommend using the USB port on your computer.</b><br/><br/>Insert the smartwatch into the appropriate charging cable section. A charging screen appears on the screen to indicate that your smartwatch is charging." />
              <Faq key="2"
                title="How do I power on my smartwatch?"
                description="On the X-Five Round model, press and hold the side button for a few seconds to turn on the device. Otherwise, tap and hold the screen of your smartwatch for 5 seconds until you see the X-FIVE Smartwatch logo appear on the watch screen, indicating the smartwatch has powered on." />
              <Faq key="3"
                title="How do I power off my smartwatch?"
                description="On the main clock face, swipe down from the top of the screen to reveal the notification panel. Tap the ‘Settings’ icon and you will be taken to the settings screen. Scroll down to ‘Power off’. After tapping on this selection, the device will ask if you’re sure you would like to turn off the device. Once you tap on the blue check mark, the device will power off immediately." />
              <Faq key="4"
                title="How do I pair my smartwatch to my phone?"
                description="First download and install the X-FIVE Wearables App to your phone (App Store or Google Play Store). Once the X-FIVE Wearables App is installed, open it to create an account, following the on-screen prompts to help you get set up. Once your account is set up, the application will help you connect to your device. We highly encourage allowing all notifications &amp; pairing access to your Smartphone.<br/><br/>To connect &amp; sync your app to your device, please follow the on-screen instructions while setting up your account." />
              <Faq key="5"
                title="How do I set the time?"
                description="You do not have to set the time on your smartwatch. Once you pair your smartwatch with the app on your phone, your smartwatch automatically syncs the correct time from your phone." />
              <Faq key="6"
                title="How long should I charge the smartwatch to get a full battery?"
                description="Your X-FIVE Smartwatch takes about 1-2 hours to fully charge." />
              <Faq key="7"
                title="How long will my smartwatch stay charged after fully charging it?"
                description="Battery life is based on usage. Your smartwatch has a battery life of 14 days (standby time). If you use your smartwatch often for notifications, sports mode, etc., your smartwatch battery will not last as long." />
              <Faq key="8"
                title="How do I turn on the motion gesture for my smartwatch?"
                description="Motion Gesture should be on automatically as soon as you power on your smartwatch. However, if you find that it’s not working, from the X-Five wearables app, tap the ‘Menu’ located on the top left of the home screen. Then tap on ‘Settings’. From here, tap on ‘Motion Gesture’ to access this function and set it up. You can control the time in which this feature is active throughout the day and toggle it on/off. If you want this feature to always be on, set the time constraints to be 12:00AM to 12:00AM." />
              <Faq key="9"
                title="How do I turn up the brightness on my smartwatch?"
                description="From the Settings menu on your X-FIVE Smartwatch, tap the brightness icon. Then tap on the minus icon to reduce the brightness or the plus icon to increase the brightness. Once you have set the brightness the way you like, swipe right on the screen to exit and save your changes.<br><br>You can also access the brightness section on your smartwatch by utilizing the quick toggles/notification panel found on your device by tapping on the sun icon found there." />
              <Faq key="10"
                title="How do I change the clock face on my smartwatch?"
                description="From the main clock face screen on your Smartwatch, hold the center of the screen for 3 seconds to switch the clock face. Swipe from left to right to see the selections. Tap on the clock face you want. Your smartwatch will vibrate once the clock face has changed." />
              <Faq key="11"
                title="How do I set a custom smartwatch face for my smartwatch?"
                description="To set a Custom Watch Face, go to the X-FIVE Wearables App. From the Watch Faces page, tap on the Custom watch face panel to begin editing. You can add your own image, format text, and put your favorite features on display. Hit Save/Done to apply." />
              <Faq key="12"
                title="How do I get my custom smartwatch face to stay on my smartwatch?"
                description="If you reset your smartwatch after downloading your custom watch face, you will need to re-download that watch face, to have it display on your smartwatch again." />
              <Faq key="13"
                title="How do I turn off the vibration feature of my smartwatch?"
                description="To turn off vibration on your smartwatch quickly, on your main clock face, swipe down to reveal the notification panel. Near the ‘Weather’ icon, you will see a small device icon, this is the vibration control section. Tap it to turn it off/on as you need to." />
              <Faq key="14"
                title="Why won’t my alarm clock setting save?"
                description="Alarms cannot be set within 2 minutes of the current time. Your alarm clock will only save alarms that are 3 minutes after the current time." />
              <Faq key="15"
                title="My smartwatch isn’t receiving notifications."
                description="In the X-FIVE Wearables App, tap the ‘Menu’ icon and tap settings then, go to Watch Notifications. Then, you may select the notifications you wish to receive on your Smartwatch." />
              <Faq key="16"
                title="I turned on notifications but still cannot receive them, what do I do?"
                description="Check that the Bluetooth on your phone is on and that your phone can receive notifications.<br/><br/><b>For Android users:</b> Check that you have enabled the X-FIVE Wearables app to access your notifications. You can do this in your phone’s native settings menu or go to the device page in the app and click on ‘Notifications’ and then click the ‘Notification Access’ link. From the ‘Notifications’ page, you can toggle on all apps you wish to receive notifications for on your smartwatch.<br><br><b>For iOS users:</b> Go to the device page in the app and click on ‘Notifications’ to toggle on all apps you wish to receive notifications for on your smartwatch.<br><br>Note: Notification permissions differ between iOS and Android users." />
              <Faq key="17"
                title="How do I view my notifications on my smartwatch?"
                description="If you don’t read the notification when it arrives, you can check it at a later time by navigating to Messages on your Smartwatch. To view unopened messages and notifications on your X-FIVE Smartwatch, on the main clock face screen, swipe up from the bottom of the screen to reveal the Messages screen. Tap on any message found in this section to reveal more information about it. To exit the messages feature, swipe down from the top of the screen or swipe right on the screen." />
              <Faq key="18"
                title="How do I turn off Notifications?"
                description="To turn off all or certain notifications with the X-FIVE Wearables App, go to the Watch Notifications in the settings menu. You can also mute all notifications by turning on Do Not Disturb mode." />
              <Faq key="19"
                title="How do I turn “Do Not Disturb” mode on?"
                description="Tap Do Not Disturb under settings in the app and set a time period to prevent your Smartwatch from receiving all notifications (other than alarms). Once active, it takes 3-5 minutes to begin working." />
              <Faq key="20"
                title="Can I adjust the active hours for the sedentary reminder?"
                description="At this time, the active hours are from 10:00 AM - 6:00 PM and cannot be changed." />
              <Faq key="23"
                title="How do I turn on the Continuous Heart Rate feature? "
                description="To activate this feature, navigate to settings in the app and tap on Continuous Heart Rate Detection to toggle it on and off. While this feature is active, your smartwatch will continuously record your heart rate every 30 minutes through-out the day. This data will automatically sync with your app when your X-FIVE Smartwatch is within Bluetooth range of your smartphone. When continuous heart rate detection is turned on, your sleep statistics are more accurate." />
              <Faq key="24"
                title="How do I start Sports Mode on my smartwatch?"
                description="On your smartwatch, scroll to the Sports Mode feature. Tap Sports Mode to view sports. To select a sport and begin tracking Sports Mode data, tap on the desired sport to begin tracking data for it." />
              <Faq key="25"
                title="How do I stop Sports Mode on my smartwatch?"
                description="To stop Sports Mode, swipe your finger across your smartwatch screen from right to left, then tap the stop button. You can also pause Sports Mode by tapping the pause button on this same screen." />
              <Faq key="26"
                title="Why isn’t my Sports Mode data on my smartwatch syncing to my app?"
                description="Sports Mode data will appear on your app once you have ended your activity. If you end a session in Sports Mode before 3 minutes has passed, data will not sync to your app. You need to log at least 3 minutes of activity in any sport for Sports Mode data to appear in your app." />
              <Faq key="27"
                title="Do I need to have my smartphone near my smartwatch while doing Sports Mode?"
                description="You can perform the sport either near your smartphone or away/not connected to your smart- phone. Once the device comes within Bluetooth range of your smartphone, it will automatically sync with the app and the mode will appear on your home/dashboard screen in the app." />
              <Faq key="28"
                title="Can I do multiple sports mode at a time?"
                description="Sports modes should be done one at a time. After stopping one mode, ensure that the data is synced to the app first. Then start your second sports mode. Doing multiple modes at once, without syncing to the app will cause data you have acquired to be lost." />
              <Faq key="29"
                title="My smartwatch is not tracking data properly, what can I do to fix it?"
                description="If you can’t sync your X-FIVE Smartwatch, or if you have trouble with connected GPS or tracking stats, you can restart your smartwatch from your wrist. From the main clock face, swipe down to reveal the notification panel and tap on settings. When in settings, scroll down to ‘Power Off’ and tap on it. Tap on the blue check mark that appears next to power off your device. Allow your device a few minutes to fully shut down. Then tap and hold the screen of your device for 3-5 seconds to turn it back on to complete your restart." />
              <Faq key="30"
                title="Can my smartwatch play music?"
                description="While your smartwatch cannot play music, you can use it as a remote to control the music on your phone. The music remote allows you to play/pause, skip forward, or skip to previous music from your music apps." />
              <Faq key="31"
                title="Why won’t the Music Remote on my smartwatch work with my music app on my phone?"
                description="Your X-FIVE Smartwatch is only compatible with various applications such as Apple Music, Spotify, and Pandora at this time. It does not support other third-party applications." />
              <Faq key="32"
                title="How do I take a picture with my smartwatch?"
                description="To snap photos using your X-FIVE Smartwatch, navigate to the camera remote function. In the X-FIVE Wearables App, navigate to the settings page and tap Camera Remote. This will launch the built-in app camera. For iOS Users: Tapping on Camera Remote will advise you to open up your native camera to take pictures with your smartwatch. To take a picture, tap on the Camera Remote icon on your smartwatch or shake your wrist and your phone will take a picture." />
              <Faq key="33"
                title="How do I view the weather for the week on my smartwatch?"
                description="First, make sure that your smartwatch is connected to your phone. The weather will not show on your smartwatch until it’s connected. Once done, go to the Weather screen on your smartwatch, you can tap the screen to view more weather data." />
              <Faq key="34"
                title="The weather feature in my smartwatch is not updated."
                description="To correct this, use the X-FIVE Wearables application to connect your device to your phone again. Once your smartwatch is fully paired to the app, open the X-FIVE Wearables App. Navigate to the settings page, scroll down and tap on ‘Weather’. Your location should be automatically found. Once done, instantly the smartwatch will update with the latest weather readings." />
              <Faq key="35"
                title="How do I set my language on my smartwatch?"
                description="Tap Language under Settings in the X-Five wearables app. Once in language, you will then be able to change the language on your Smartwatch. Choose from a variety of languages." />
              <Faq key="36"
                title="How do I change the unit of measurement on my smartwatch?"
                description="Tap Units under Settings to change the units of measurement reflected on your Smartwatch and/or app. Choose between the metric and imperial systems of measurement for your smartwatch." />
              <Faq key="37"
                title="Can I swim with my X-FIVE smartwatch?"
                description="We advise not to swim with your device. Although the X-FIVE Smartwatch is IP67 water resistant, it is not IP68 waterproof which means you should not submerge your smartwatch in water deeper than one meter for longer than 30 minutes." />
              <Faq key="38"
                title="How do I erase all data on my smartwatch?"
                description="Factory Reset your smartwatch to clear all of the data on your smartwatch. To reset your smartwatch, go to settings on your smartwatch and scroll down to the green ‘Reset’ icon. After tapping on this, the device will ask if you’re sure you would like to reset your device. Once you tap on the blue check mark, your X-FIVE smartwatch will reset and clear all data." />
              <Faq key="39"
                title="How long can I wear my smartwatch for?"
                description="We recommend removing your smartwatch from your wrist at least once per day to clean your smartwatch’s straps." />
              <Faq key="40"
                title="How do I clean the straps on my Smartwatch?"
                description="We recommend cleaning your band and wrist regularly - especially after intense workouts or sweating. Rinse the band with water or wipe it with a small amount of rubbing alcohol. Do NOT use hand soap, dish soap, hand sanitizer, cleaning wipes or household cleaners, which could get trapped beneath the band and irritate your skin. Always pat dry the band before putting it back on." />
              <Faq key="41"
                title="I updated the firmware on my smartwatch and I am having issues."
                description="You need to reset your smartwatch after you update the firmware. This is to ensure that the old firmware has been fully replaced and it cannot be done while your device is still running." />
              <Faq key="42"
                title="Why did my smartwatch reset automatically when I updated the firmware on it?"
                description="That’s normal. After you update your smartwatch firmware, it will automatically reset itself with the new firmware for optimal functionality." />
            </FaqBlock>
          </ContentArea>
        </Grid>
      </section>
    </Layout>
  </>

  )
}


export default Faqs
